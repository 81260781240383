<template>
  <div style="background: #fff">
    <div class="free-header">
      <img
        v-if="productKey === 'net'"
        src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/extendInfo/net.png"
        width="100%"
        height="auto"
      />
      <img
        v-if="productKey === 'energy'"
        src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/extendInfo/energy.png"
        width="100%"
        height="auto"
      />
    </div>
    <div class="free-contaion">
      <div class="free-title">
        <div class="one">
          家庭{{ productKey == "net" ? "网络" : "能源" }}开通情况
        </div>
        <div class="two">(*为必填)</div>
      </div>
    </div>
    <van-form @submit="onSubmit" label-width="120px">
      <van-cell-group>
        <van-field
          :disabled="disabled"
          v-model="infoFrom.address"
          required
          :rules="[{ required: true }]"
          label="开通地址"
          placeholder="请输入开通地址"
        />
        <!-- 委托看房时间*		 -->
        <van-field
          :disabled="disabled"
          :value="infoFrom.time"
          readonly
          required
          :rules="[{ required: true }]"
          label="开通时间"
          placeholder="请选择开通时间"
          @click="isShow = !disabled"
          right-icon="notes-o"
        />
        <van-popup v-model="isShow" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            :min-date="minDate"
            :max-date="maxDate"
            type="date"
            title="选择年月日"
            @confirm="confirm"
            @cancel="isShow = false"
          />
        </van-popup>
      </van-cell-group>
      <div class="message">
        如遇到问题请至邮件info@clozhome或您的顾问获取帮助
      </div>
      <div class="spacing-height"></div>
      <div class="bottom-flex" v-if="!disabled">
        <div class="flex-left">
          <div class="left-consult" @click="serviceClick">
            <van-image
              width="23"
              height="20"
              fit="scale-down"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/consult.png"
            /><br />咨询客服
          </div>
        </div>
        <div class="flex-right">
          <van-button v-if="disabled" class="default" type="default"
            >立即提交</van-button
          >
          <van-button
            v-if="!disabled"
            class="primary"
            type="primary"
            native-type="submit"
            >立即提交</van-button
          >
        </div>
      </div>
    </van-form>
  </div>
</template>
<script>
import {
  addUpdateApply,
  getUpdateApplyStatus,
  getProductUpdateInfo,
} from "@/api/informations/information";
import { timeStampChange } from "@/utils/index";
export default {
  name: "HouseholdEnergy",
  data() {
    return {
      infoFrom: {},
      product: {},
      isHouseWork: 1,
      isShow: false,
      disabled: false,
      productKey: undefined,
      minDate: new Date(1910, 0, 1),
      maxDate: new Date(2080, 10, 1),
      currentDate: new Date(),
      processId: undefined,
      env: "",
    };
  },
  created() {
    this.processId = this.$route.query.processId;
    this.productKey = "net";
    this.onload();
  },
  mounted() {
    let that = this;
    this.$nextTick(() => {
      document.addEventListener("UniAppJSBridgeReady", function () {
        uni.getEnv(function (res) {
          that.env = res;
          console.log("当前环境：" + JSON.stringify(res));
        });
      });
    });
  },
  methods: {
    onload() {
      // this.disabled = true;
      getUpdateApplyStatus({ processId: this.processId }).then((res) => {
        if (res.data) {
          this.productKey = res.data.productKey;
          if (res.data && res.data.status == 1) {
            this.disabled = true;
            this.infoFrom = JSON.parse(res.data.updateInfo);
          }
          this.getProductUpdateInfo(res.data.productId);
        }
      });
    },
    getProductUpdateInfo(productId) {
      getProductUpdateInfo({ productId: productId }).then((res) => {
        this.product = res.data;
      });
    },
    confirm(data) {
      this.infoFrom.time = timeStampChange(data);
      this.isShow = false;
    },
    serviceClick() {
      console.log("点击联系客服===>");
      if (this.env.miniprogram) {
        uni.navigateTo({
          url: "/otherPages/webview/showContact",
        });
      } else {
        enterMeiQiaService.postMessage(
          JSON.stringify({
            serviceName: this.product.productName,
            applyType: this.product.updateApplyName,
          })
        );
      }
    },
    // 提交接口
    onSubmit() {
      var that = this;
      console.log("点击提交===>");
      if (this.disabled) {
        return;
      }
      let obj = {
        updateApplyJson: JSON.stringify(this.infoFrom),
        processId: this.processId,
      };
      console.log("HouseholdEnergy====obj结果===>", obj);
      addUpdateApply(obj).then((res) => {
        console.log("提交补充表单结果===>", res);
        if (res.code == 0) {
          var obj2 = JSON.stringify({
            orderNo: res.data.orderNo,
            orderTime: res.data.orderTime,
            recordId: res.data.recordId,
          });
          var obj3 = encodeURIComponent(obj2);
          // if (that.env.miniprogram) {
          //   uni.navigateTo({
          //     url: `/applyPages/extendInfo?orders=${obj3}`,
          //   });
          // } else {
          //   enterApplyResult.postMessage(
          //     JSON.stringify({
          //       orderNo: res.data.orderNo,
          //       orderTime: res.data.orderTime,
          //       recordId: res.data.recordId,
          //     })
          //   );
          // }
          try {
            enterApplyResult.postMessage(
              JSON.stringify({
                orderNo: res.data.orderNo,
                orderTime: res.data.orderTime,
                recordId: res.data.recordId,
              })
            );
          } catch (error) {
            uni.navigateTo({
              url: `/applyPages/extendInfo?orders=${obj3}`,
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom-flex {
  padding-top: 10px;
  padding-bottom: 10px;
}
/deep/.van-icon-notes-o::before {
  font-size: 22px;
}
/deep/.van-button {
  height: 40px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
}
.flex-right {
  border-radius: 4px;
  overflow: hidden;
}
</style>
